<template>
  <div>
    <h3>Admins</h3>
    <template v-if="fetching"> FETCHING </template>
    <template v-else>
      <table class="table adminsTable">
        <thead>
          <tr>
            <th>Email</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="3">
              <div class="row col-12">
                <button
                  @click="addAdmin"
                  class="mx-auto btn adminsTable__change"
                >
                  Create New
                </button>
              </div>
            </td>
          </tr>
          <template v-for="admin in admins">
            <tr :key="admin._id">
              <td>{{ admin.email }}</td>
              <td>{{ admin.name }}</td>
              <td>
                <button
                  class="btn adminsTable__change"
                  @click="changePassword(admin._id, admin.email, admin.name)"
                >
                  Change Password
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
    <template v-if="adminToEdit">
      <change-admin-password
        :user="adminToEdit"
        ref="changePassRef"
        @close="closeChangePass"
      />
    </template>
    <template v-if="newAdmin">
      <create-new-admin ref="newAdminRef" @close="closeNewAdmin" />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChangeAdminPassword from "@/view/pages/admins/ChangeAdminPassword";
import CreateNewAdmin from "@/view/pages/admins/CreateNewAdmin";
import { GET_ADMINS } from "@/core/services/store/admins.module";
export default {
  name: "Admins",
  components: {
    ChangeAdminPassword,
    CreateNewAdmin,
  },
  data: () => ({
    adminToEdit: null,
    newAdmin: false,
  }),
  mounted() {
    this.$store.dispatch(GET_ADMINS);
  },
  methods: {
    changePassword(id, email, name) {
      this.adminToEdit = { id, email, name };
      setTimeout(() => {
        this.$refs.changePassRef.showModal();
      }, 200);
    },
    closeChangePass() {
      this.$refs.changePassRef.closeModal();
      this.$store.dispatch(GET_ADMINS);
      setTimeout(() => {
        this.adminToEdit = null;
      }, 200);
    },
    addAdmin() {
      this.newAdmin = true;
      setTimeout(() => {
        this.$refs.newAdminRef.showModal();
      }, 200);
    },
    closeNewAdmin() {
      this.$refs.newAdminRef.closeModal();
      this.$store.dispatch(GET_ADMINS);
      setTimeout(() => {
        this.newAdmin = false;
      }, 200);
    },
  },
  computed: {
    ...mapState({
      admins: ({ admins }) => admins.admins,
      fetching: ({ admins }) => admins.fetching,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
