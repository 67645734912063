<template>
  <div>
    <b-modal
      id="change-admin-password"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="change-admin-password"
      @hide="close"
    >
      <span
        class="modal-close-custom close-btn shadow-sm"
        @click="$bvModal.hide('change-admin-password')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Change Admin Password</h3>

      <div class="row col-12 py-3">
        Change Password for <code> {{ user.name }}</code
        >@<code>{{ user.email }}</code>
      </div>
      <form class="form" @submit.prevent="changePassword">
        <div class="form-group form-check">
          <label class="input-label" for="password">Old Password</label>
          <b-form-input
            required
            class="form-control"
            id="password"
            v-model="form.oldPassword"
            type="password"
          ></b-form-input>
        </div>
        <div class="form-group form-check">
          <label class="input-label" for="password">New Password</label>
          <b-form-input
            required
            class="form-control"
            id="password"
            v-model="form.newPassword"
            type="password"
          ></b-form-input>
        </div>
        <div class="row col-12">
          <span class="d-none mx-auto text-danger" :class="hasError">{{
            processingError
          }}</span>
        </div>
        <div class="row col-12">
          <button
            class="btn submit-modal-custom mx-auto"
            :disabled="processing"
          >
            Change Password
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: {
    user: Object,
  },
  data: () => ({
    processingError: null,
    processing: false,
    hasError: null,
    form: {
      oldPassword: "",
      newPassword: "",
    },
  }),
  methods: {
    showModal: function () {
      this.$bvModal.show("change-admin-password");
    },
    closeModal: function () {
      this.$bvModal.hide("change-admin-password");
    },
    close: function () {
      this.$emit("close");
    },
    async changePassword() {
      const request = {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.newPassword,
        userId: this.user.id,
      };
      this.processing = true;
      this.hasError = "d-none";
      const res = await ApiService.post("/user/changeAdminPassword", request);
      this.processing = false;
      const { data } = res;
      console.log(
        "file: ChangeAdminPassword.vue | line 94 | changePassword | data",
        data
      );
      if (data.success === 0) {
        //
        this.processingError = data.error.message;
      } else {
        this.processingError = data.message;
      }
      this.hasError = "d-block";
      setTimeout(() => {
        this.hasError = "d-none";
      }, 10000);
    },
  },
};
</script>
