<template>
  <div>
    <b-modal
      id="new-admin-create"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-admin-create"
      @hide="close"
    >
      <span
        class="modal-close-custom close-btn shadow-sm"
        @click="$bvModal.hide('new-admin-create')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Create Admin</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >

      <form class="form" @submit.prevent="createAdmin">
        <div class="form-group form-check">
          <label class="input-label" for="userName">Admin Name</label>
          <b-form-input
            required
            class="form-control"
            id="userName"
            v-model="form.userName"
            type="text"
          ></b-form-input>
        </div>
        <div class="form-group form-check">
          <label class="input-label" for="email">Admin Email</label>
          <b-form-input
            required
            class="form-control"
            id="email"
            v-model="form.email"
            type="email"
          ></b-form-input>
        </div>
        <div class="form-group form-check">
          <label class="input-label" for="password">Password</label>
          <b-form-input
            required
            class="form-control"
            id="password"
            v-model="form.password"
            type="password"
          ></b-form-input>
        </div>
        <div class="row col-12">
          <button class="btn submit-modal-custom mx-auto">Create Admin</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  data: () => ({
    processingError: null,
    hasError: null,
    form: {
      userName: "",
      password: "",
      email: "",
    },
  }),
  methods: {
    showModal: function () {
      this.$bvModal.show("new-admin-create");
    },
    closeModal: function () {
      this.$bvModal.hide("new-admin-create");
    },
    close: function () {
      this.$emit("close");
    },
    async createAdmin() {
      const request = {
        name: this.form.userName,
        email: this.form.email,
        password: this.form.password,
      };
      this.processing = true;
      this.hasError = "d-none";
      const res = await ApiService.post("/user/createAdmin", request);
      this.processing = false;
      const { data } = res;
      if (data.success === 0) {
        //
        this.processingError = data.error.message;
      } else {
        this.processingError = data.message;
      }
      this.hasError = "d-block";
      setTimeout(() => {
        this.hasError = "d-none";
      }, 10000);
    },
  },
};
</script>
